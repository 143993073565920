import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router, useNavigate
} from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
import NavbarOne from '../siteTheme/NavbarOne';
import { Checkbox } from "@mui/material";
// import bnb from "../../assets/images/coin-logos/bnb.png";
// import btc from "../../assets/images/coin-logos/btc.png";
import line from "../../assets/images/bg_coin_line.jpg";
import Modal from 'react-bootstrap/Modal';
import Config from "../../core/config";
import { makeRequest } from "../../core/services/v1/request";
import { isEmpty } from "../../core/helper/common";
import { toast } from "../../core/lib/toastAlert";
// import { FaCloudShowersHeavy } from "react-icons/fa";
import downArrow from "../../assets/images/down-arrow.png";
import upArrow from "../../assets/images/up-arrow.png";
import { useContextData } from "../../core/context";
import Footer from "../siteTheme/Footer";

export default function Earn(props) {
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    const [stakingModal, setstakingModal] = useState({ show: false, select: {}, package: 0 });
    const [stakingList, setStakingList] = useState([]);
    const [stakingListTop, setStakingListTop] = useState([]);
    const [userBalance, setuserBalance] = useState(0);
    const [formData, setFromData] = useState({ isCheck: false, amount: 0 });
    const [error_msg, seterror_msg] = useState({ amount: "", isCheck: false });
    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        getStaking();
        getStakingNew();
    }, []);

    function getDateShow(type, tenureDays = 0) {
        const today = new Date();
        if (type === 'stakingDate') {
            return today.toDateString();
            // return `${year}-${month}-${date}`;
        }
        else {
            let futureDate = new Date();
            futureDate.setDate(today.getDate() + parseInt(tenureDays));
            // futureDate = new Date(futureDate)
            // return today.toDateString();
            return futureDate.toDateString();
            // return `${futureDate.getFullYear()}-${futureDate.getMonth() + 1}-${futureDate.getDate()}`;
        }
    }
    async function getStaking() {
        try {
            const params = {
                url: `${Config.V1_API_URL}staking/getStaking`,
                method: 'GET',
            }
            const response = await makeRequest(params);
            if (response.status && response.getStakingTblDetails) {
                setStakingList(response.getStakingTblDetails);
            }
        } catch (err) { }
    }
    async function getStakingNew() {
        try {
            const payload = {
                topApr: true
            }
            const params = {
                url: `${Config.V1_API_URL}staking/getStaking`,
                method: 'POST',
                data: payload
            }
            const response = await makeRequest(params);
            if (response.status && response.getStakingTblDetails) {
                setStakingListTop(response.getStakingTblDetails);
            }
        } catch (err) { }
    }
    async function selStake(data = {}) {
        setstakingModal({ show: true, select: data.val, package: data.indexx });
        getStakingBalance(data.val.walletCurrencyId);
    }
    async function getStakingBalance(currencyId) {
        try {
            if (myProfile && myProfile._id) {
                const params = {
                    url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                    method: 'POST',
                    body: { currencyId: currencyId }
                }
                const response = await makeRequest(params);
                if (response.status && response.data) {
                    setuserBalance(response.data.stakingAmount > 0 ? response.data.stakingAmount : 0);
                }
            }
        } catch (err) { }
    }
    const handleChange = (event) => {
        let value = event.target.value;
        switch (true) {
            case event.target.name === "amount":
                formData.amount = value;
                setFromData({
                    amount: formData.amount,
                    isCheck: formData.isCheck
                });
                validationChk();
                break;
            case event.target.name === "termsAndCondition":
                formData.isCheck = event.target.checked;
                setFromData({
                    amount: formData.amount,
                    isCheck: formData.isCheck
                });
                validationChk();

                break;
            default:
                break;
        }
    };

    function validationChk() {
        let errors = {};
        const amount = Number(formData.amount);
        if (userBalance < formData.amount) {
            errors.amount = "Insufficient balance";
        }
        if (isEmpty(amount)) {
            errors.amount = "Please enter the amount";
        }
        if (stakingModal.select.packages) {
            if (amount == 0) {
                errors.amount = "The min stake amount is " + stakingModal.select.packages[stakingModal.package].from + " " + (stakingModal.select.currencyId && stakingModal.select.currencyId.currencySymbol);
            }
            if ((stakingModal.select.packages[stakingModal.package].from) > amount) {
                errors.amount = "The min stake amount is " + stakingModal.select.packages[stakingModal.package].from + " " + (stakingModal.select.currencyId && stakingModal.select.currencyId.currencySymbol);
            }
            if ((stakingModal.select.packages[stakingModal.package].to) < amount) {
                errors.amount = "The max stake amount is " + stakingModal.select.packages[stakingModal.package].to + " " + (stakingModal.select.currencyId && stakingModal.select.currencyId.currencySymbol);
            }
        }
        if (formData.isCheck === false) {
            errors.isCheck = "Accept Terms & Conditions is required";
        }
        if (Object.keys(errors).length > 0) {
            seterror_msg({ amount: errors.amount, isCheck: errors.isCheck });
            return false;
        } else {
            seterror_msg({ amount: "", isCheck: "" });
            return true;
        }
    }
    async function submitStaking() {
        try {
            if (validationChk()) {
                let reqData = {
                    stakingId: stakingModal.select._id,
                    package: stakingModal.package,
                    amount: formData.amount
                }
                const params = {
                    url: `${Config.V1_API_URL}staking/submitStaking`,
                    method: 'POST',
                    body: reqData
                }
                setisLoading(true);
                const response = await makeRequest(params);
                let errorType = "error";
                if (response.status) {
                    errorType = "success";
                    setisLoading(false);
                    getStaking();
                    setstakingModal({
                        show: false, select: [], package: 0
                    });
                    setFromData({
                        isCheck: false, amount: 0
                    })
                }
                toast({ errorType, message: response.message });
            }
        } catch (err) { }
    }
    async function handleClose() {
        setstakingModal({
            show: false, select: [], package: 0
        });
    }
    async function backButton(){
        try {
         const length = window.history.length;
         if (length > 1) {
           window.history.back();
         } else {
           navigate("/");
         }
        } catch (err){}
    }
    return (
        <div>
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
                identify={props.identify}
                pageName="Earn"
            />
            {/* <div className="under-line-effect">
                <img src={line} alt="bg_coin_line" />
            </div>
            <div className="earn-banner-container">
                <div className="row earn-banner-inside mx-auto">
                    <div className="col-lg-12">
                        <div className="earn-header">{Config.SITENAME}</div>
                        <div className="earn-content">Don't Just HODL — Grow Your Crypto Holdings The Smart Way</div>
                    </div>
                </div>
            </div> */}
            <div className="deposit-page-top-banner">
                <div className="deposit-hero-section">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col">
                            <GoChevronLeft className="deposit-back-button-icon" onClick={()=>backButton()}/>
                            <span className="deposit-text-1">Swap</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="markets-second-section">
                <div className="container">
                    <div className="row earn-container mx-auto">
                        <div className="col-lg-12">
                            <div className="row section-break">

                                {
                                    stakingListTop.length > 0 ?
                                        stakingListTop.map((val, key) => {
                                            let planName = "";
                                            let firstPackage = {};
                                            let lastPackage = {};
                                            if (val.packages) {
                                                firstPackage = val.packages[0];
                                                lastPackage = val.packages[Object.keys(val.packages).slice(-1)];
                                            }
                                            return <div className="col-lg-4 p-0" key={key}>
                                                <div className="mr-2 ml-2 earn-col-container" onClick={() => {
                                                    selStake({ val, indexx: key })
                                                }}>
                                                    <div className="row">
                                                        <div className="col-2 img-container">
                                                            {val.currencyId.image ? <img className="imgSmall1 coin-img-2" src={val.currencyId.image} alt="stakeImg" /> : ""}
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="earn-col-big-text">
                                                                {val.currencyId.currencySymbol}
                                                            </div>
                                                            <div className="earn-col-small-text">
                                                                {planName}
                                                                {
                                                                    Object.entries(val.packages).map(([key, value], index) => {
                                                                        planName = value.packageName;
                                                                        const plaLen = Object.keys(val.packages).length;
                                                                        if (index == 3) {
                                                                            return "...";
                                                                        }
                                                                        else if (index < 3) {
                                                                            if (plaLen - 1 === index) {
                                                                                return planName;
                                                                            }
                                                                            else {
                                                                                return planName + " | ";
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-2 new-button-container">
                                                            <span className="new-button">TOP</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-9">
                                                            <div className="earn-text-secoundary">
                                                                APR
                                                            </div>
                                                            <div className="earn-text-primary-spl">
                                                                {firstPackage.interest} % ~ {lastPackage.interest} %
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="earn-text-secoundary">
                                                                Duration
                                                            </div>
                                                            <div className="earn-text-primary">
                                                                {firstPackage.tenureDays + "-" + lastPackage.tenureDays + " Days"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : ""
                                }


                                {/* <div className="col-lg-4 earn-col-main">
                                    <div className="mr-2 ml-2 earn-col-container">
                                        <div className="row">
                                            <div className="col-2 img-container">
                                                <img src={bnb} alt="bnb" className="coin-img-1" />
                                                <img src={btc} alt="btc" className="coin-img-2" />
                                            </div>
                                            <div className="col-8">
                                                <div className="earn-col-big-text">
                                                    ZRO - USDT Buy Low 2
                                                </div>
                                                <div className="earn-col-small-text">
                                                    Dual Asset | Advanced
                                                </div>
                                            </div>
                                            <div className="col-2 new-button-container">
                                                <span className="new-button">NEW</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-10">
                                                <div className="earn-text-secoundary">
                                                    APR
                                                </div>
                                                <div className="earn-text-primary-spl">
                                                    159.66 % ~ 405.00 %
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="earn-text-secoundary">
                                                    Duration
                                                </div>
                                                <div className="earn-text-primary">
                                                    1 Day
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 p-0">
                                    <div className="mr-2 ml-2 earn-col-container">
                                        <div className="row">
                                            <div className="col-2 img-container">
                                                <img src={bnb} alt="bnb" className="coin-img-1" />
                                                <img src={btc} alt="btc" className="coin-img-2" />
                                            </div>
                                            <div className="col-8">
                                                <div className="earn-col-big-text">
                                                    ZRO - USDT Buy Low
                                                </div>
                                                <div className="earn-col-small-text">
                                                    Dual Asset | Advanced
                                                </div>
                                            </div>
                                            <div className="col-2 new-button-container">
                                                <span className="new-button">NEW</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-10">
                                                <div className="earn-text-secoundary">
                                                    APR
                                                </div>
                                                <div className="earn-text-primary-spl">
                                                    159.66 % ~ 405.00 %
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="earn-text-secoundary">
                                                    Duration
                                                </div>
                                                <div className="earn-text-primary">
                                                    1 Day
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row all-product-container">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            All Products
                                        </div>
                                    </div>
                                    <div className="row grid-header mt-4 mb-4">
                                        {/* mobile-hide */}
                                        <div className="col-2 p-1 justify-center">
                                            <span>Coins</span>
                                        </div>
                                        <div className="col-2 p-1 justify-center">
                                            <span>APR</span>
                                        </div>
                                        <div className="col-2 p-1 justify-center">
                                            <span>Duration</span>
                                        </div>
                                        <div className="col-2 p-1 justify-center">
                                            <span>Maturity Days</span>
                                        </div>
                                        <div className="col-2 p-1 justify-center">
                                            <span>Lock Amount</span>
                                        </div>
                                        <div className="col-2 p-1 justify-center">
                                            <span>Stake</span>
                                        </div>
                                    </div>

                                    <div className="row grid-body-container">
                                        <div className="col-12">
                                        </div>
                                        {
                                            stakingList.length > 0 ?
                                                stakingList.map((val, key) => {
                                                    let firstPackage = {};
                                                    let lastPackage = {};
                                                    if (val.packages) {
                                                        firstPackage = val.packages[0];
                                                        lastPackage = val.packages[Object.keys(val.packages).slice(-1)];
                                                    }
                                                    return (
                                                        <div className="main-container" key={key}>
                                                            <div className="row mb-3 mt-2 data-container" onClick={() => {
                                                                const stakingListUpd = stakingList.map(item =>
                                                                    item._id === val._id ? { ...item, isExpand: val.isExpand ? false : true } : item
                                                                );
                                                                setStakingList(stakingListUpd);
                                                            }}
                                                            >
                                                                {val.currencyId &&
                                                                    <div className="col-2 mobile-hide p-1">
                                                                        <div className="grid-img-container justify-center">
                                                                            <span>{val.currencyId.image && <img className="imgSmall" src={val.currencyId.image} alt="bnb" />}</span>
                                                                            <span>{val.currencyId.currencySymbol}</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {val.currencyId &&
                                                                    <div className="col-2 web-hide p-1">
                                                                        <div className="grid-img-container">
                                                                            <div className="justify-center">{val.currencyId.image && <img className="imgSmall" src={val.currencyId.image} alt="bnb" />}</div>
                                                                            <div className="justify-center">{val.currencyId.currencySymbol}</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-2 p-1 justify-center">
                                                                    <span className="grid-duration-text">{firstPackage.interest + "%" + " - " + lastPackage.interest + "%"}</span>
                                                                </div>
                                                                <div className="col-2 p-1  justify-center">
                                                                    <span className="grid-apr-text">{firstPackage.tenureDays + " - " + lastPackage.tenureDays + " Days"}</span>
                                                                </div>
                                                                <div className="col-2 p-1  justify-center">
                                                                    <span className="grid-duration-text">{val.maturityDays} Days</span>
                                                                </div>
                                                                <div className="col-2 p-1  justify-center">
                                                                    {/* <span className={val.status === 1 ? 'grid-chip-stay' : 'grid-chip-success'}>{val.status === 1 ? 'Active' : 'De-Active'}</span> */}
                                                                    <span className="grid-apr-text">{firstPackage.from + " - " + lastPackage.to + " " + val.currencyId.currencySymbol}</span>
                                                                </div>
                                                                <div className="col-2 p-1  col-lg-2 arrow-container">
                                                                    <div>
                                                                        {
                                                                            val.isExpand && <div className="down-arrow justify-center">
                                                                                <img src={downArrow} alt="down-arrow" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !val.isExpand && <div className="up-arrow justify-center">
                                                                                <img src={upArrow} alt="up-arrow" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="expand-table">
                                                                {
                                                                    (val.isExpand && val.packages && Object.keys(val.packages).length > 0) ?
                                                                        Object.entries(val.packages).map(([key, value], index) => {
                                                                            // val.packages.map((value, index) => {
                                                                            return (
                                                                                <div className="row mb-3">
                                                                                    {/* mobile-hide */}
                                                                                    <div className="col-2 p-1">
                                                                                        <span className="grid-duration-text"></span>
                                                                                    </div>
                                                                                    <div className="col-2 p-1  justify-center">
                                                                                        <span className="grid-duration-text">{value.interest}%</span>
                                                                                    </div>
                                                                                    <div className="col-2 p-1  justify-center">
                                                                                        <span className="grid-apr-text">{value.tenureDays + " Days"}</span>
                                                                                    </div>
                                                                                    <div className="col-2 p-1  justify-center">
                                                                                        <span className="grid-duration-text">{value.maturityDays} Days</span>
                                                                                    </div>
                                                                                    <div className="col-2 p-1  justify-center">
                                                                                        {/* <span className={val.status === 1 ? 'grid-chip-stay' : 'grid-chip-success'}>{val.status === 1 ? 'Active' : 'De-Active'}</span> */}
                                                                                        <span className="grid-apr-text">{value.from + " - " + value.to + " " + val.currencyId.currencySymbol}</span>
                                                                                    </div>
                                                                                    <div className="col-2 p-1  col-lg-2 justify-center p-0">
                                                                                        <span className="grid-btn" onClick={() => { selStake({ val, indexx: index }) }}>Stake Now</span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : ""
                                                                }
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <span className="grid-duration-text">Record Not Found</span>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={stakingModal.show === true} onHide={() => handleClose()} className="modal-popup">
                <Modal.Body>
                    {stakingModal.select && stakingModal.select && stakingModal.select.currencyId &&
                        <>
                            <div className="row dropdown-container">
                                <div className="col-11">
                                    {stakingModal.select.currencyId.currencySymbol} Fixed staking
                                </div>
                                <div className="col-1 close-btn" onClick={() => handleClose()}>
                                    X
                                </div>
                            </div>
                            <hr></hr>
                            <div className="flexible-saving">
                                <div className="row">
                                    <div className="col-12">
                                        <span>Amount</span>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                onChange={handleChange}
                                                name="amount"
                                                id="amount"
                                                value={formData.amount}
                                                className="form-control"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                placeholder={(stakingModal.select.packages && stakingModal.select.packages[stakingModal.package].from) + "-" + (stakingModal.select.packages[stakingModal.package].to)}
                                            />
                                            <span className="input-group-text" id="basic-addon1">
                                                <span className="curPointer" onClick={() => { setFromData({ amount: userBalance, isCheck: formData.isCheck }) }}>Max</span>
                                                {stakingModal.select.currencyId.image && <img src={stakingModal.select.currencyId.image} className="input-group-image" alt="img" />}
                                                {stakingModal.select.currencyId.currencySymbol}
                                            </span>
                                        </div>
                                        {error_msg && <span className="error_msg"> {error_msg.amount} </span>}
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <span>Available Balance ({userBalance + " " + stakingModal.select.currencyId.currencySymbol})</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-10">
                                        <span className="earn-col-small-text">Estimated APR</span>
                                    </div>
                                    <div className="col-2 text-end">
                                        <span>{stakingModal.select.packages && stakingModal.select.packages[stakingModal.package].interest}%</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <span className="earn-col-small-text">Staking Period</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-8">
                                        <span>&gt;=Stake date</span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <span>{getDateShow('stakingDate')}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-8">
                                        <span>&lt;= Auto Redemption Date</span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <span>{getDateShow('autoRedemptionDate', stakingModal.select.packages[stakingModal.package].tenureDays)}</span>
                                    </div>
                                </div>
                                {/* <div className="row mt-2">
                            <div className="col-10">
                                <span className="earn-col-small-text">Your currenct staking amount</span>
                            </div>
                            <div className="col-2 text-end">
                                <span>0 {stakingModal.select.currencyId.currencySymbol}</span>
                            </div>
                        </div> */}
                                <div className="row mt-2">
                                    <div className="col-10">
                                        <span className="earn-col-small-text">Duration</span>
                                    </div>
                                    <div className="col-2 text-end">
                                        {stakingModal.select.packages && stakingModal.select.packages[stakingModal.package] ? <span>{stakingModal.select.packages[stakingModal.package].tenureDays} Days</span> : <span></span>}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-10">
                                        <span className="earn-col-small-text">Estimate daily return</span>
                                    </div>
                                    <div className="col-2 text-end">
                                        {formData.amount > 0 && stakingModal.select.packages && stakingModal.select.packages[stakingModal.package].interest ? <span>{(((formData.amount * stakingModal.select.packages[stakingModal.package].interest) / 100) / 365).toFixed(4)} {stakingModal.select.currencyId.currencySymbol}</span> : <span>-- {stakingModal.select.currencyId.currencySymbol}</span>}
                                    </div>
                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="col-12">
                                        <span><span></span><span className="read-me">
                                            <Checkbox
                                                value={formData.isCheck}
                                                onChange={handleChange}
                                                inputProps={{ "aria-label": "controlled" }}
                                                label="Take Profit"
                                                name="termsAndCondition"
                                            />
                                            I have read and Agree to </span> <span className="read-me-link">Terms of Services and Privacy Policy</span></span>
                                    </div>
                                    {error_msg && <span className="error_msg"> {error_msg.isCheck} </span>}
                                </div>
                                <div className="row mb-3 popup-grid-btn">
                                    <div className="col-4 justify-center ">
                                        <span className="grid-btn" onClick={() => submitStaking()} disable={isLoading}>Stake Now</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
}
